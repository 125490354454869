<template>
  <aside>
    <section class="mb-20 white-box bank-infos">
      <aRow type="flex" :gutter="20">
        <aCol flex="50px">
          <aAvatar :size="50">
            <aIcon type="bank" />
          </aAvatar>
        </aCol>
        <aCol flex="auto" style="flex: 1 1">
          <div class="name">
            <aRow type="flex" justify="space-between">
              <aCol :span="19">
                <div class="h-skeleton" />
              </aCol>
              <aCol>
                <img
                  src="@/assets/images/dashboard/banks/info.png"
                  alt="info"
                  width="14"
                  @click="true"
                  style="opacity: 0.3"
                />
                <img
                  src="@/assets/images/dashboard/banks/switch.png"
                  alt="switch"
                  width="14"
                  class="c-pointer ml-5"
                  @click="openSwitchBankAccountModal = true"
                />
              </aCol>
            </aRow>
          </div>
          <div class="account">
            <div class="h-skeleton inline" style="width: 80px" />

            <div class="h-skeleton inline" style="width: 80px" />
          </div>
          <div class="company"><div class="h-skeleton" /></div>
        </aCol>
      </aRow>
    </section>

    <section class="white-box side-menu">
      <div class="h-skeleton mb-20" />
      <div class="h-skeleton mb-20" />
      <div class="h-skeleton" />
    </section>

    <section class="side-menu no-bg">
      <div class="h-skeleton mt-10 mb-20" style="background: #ddd" />
      <div class="h-skeleton mb-10" style="background: #ddd" />
    </section>

    <aModal
      :footer="false"
      :visible="openSwitchBankAccountModal"
      @cancel="openSwitchBankAccountModal = false"
    >
      <template #title>
        <aIcon type="bank" class="mr-5" /> Selecionar Banco
      </template>
      <SelectBankModal
        v-if="openSwitchBankAccountModal"
        :userIsVerified="userIsVerified"
        @verificationSuccessfully="verificationSuccessfully"
      />
    </aModal>
  </aside>
</template>

<script>
import SelectBankModal from "../modals/SelectBankModal.vue";

export default {
  components: { SelectBankModal },
  name: "BankInfosSection",
  props: {
    userIsVerified: Boolean,
  },
  data() {
    return {
      openSwitchBankAccountModal: true,
    };
  },
  mounted() {},
  methods: {
    verificationSuccessfully(bankId, verificationData) {
      this.openSwitchBankAccountModal = false;
      this.$emit("verificationSuccessfully", bankId, verificationData);
    },
  },
};
</script>

<style lang="sass" scoped>
.white-box
  background: #fff
  padding: 15px
  border-radius: 8px

.side-menu.no-bg
  padding: 10px 15px
  .item
    border-bottom: 1px solid #d7d7d7
    .img
      margin-right: 5px

.side-menu
  .item
    padding: 10px
    border-bottom: 1px solid #d7d7d7
    display: flex
    align-items: center
    font-size: 13px
    transition: .3s
    cursor: pointer
    color: #595959
    .img
      margin-right: 5px
  .item:hover
    background: rgba(0,0,0,0.03)
  .item:last-child
    border-bottom: 0

.bank-infos
  color: #595959
  .name
    font-size: 14px
    font-weight: 600
  .account
    font-size: 10px
    color: #666
  .company
    font-size: 10px
    color: #666
</style>
